body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login-container {
  position: absolute;

  margin-top: 23%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /* background-image:url('../../assets/BG-Image.jpg'); */
 
}

.form-container {
  background-color: #000000;
  color: #e8e8e8;
  font-size: 15px;
  padding: 21px;
  box-shadow: 2px 0px 3px 3px #f3f3f3;
  -webkit-box-shadow: 2px 0px 3px 3px #f3f3f3;
}
.form-container .form-control {
  background-color: #000;
  color: #e8e8e8;
}
.btn-clr {
  background-color: #e9b42a;
  font-weight: 600;
  font-size: 17px;
}
.img-width {
  width: 137px;
  margin-left: 171px;
  margin-bottom: 11px;
  margin-top: 11px;
}

._loading_overlay_content {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background-color: #fdfdfd94;
}
body .css-fgquts-wrapper-Loader {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.displaySpinner {
  display: block;
}

:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: 0.75rem;
}
/* 
  body {
    background-image: url("../../Assets/bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  } */

#login-form {
  /* background-image: url("../../assets/bg.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-color: #958f88;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: 0.75rem 1.5rem;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(0.75rem + 0.75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(0.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(0.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color:#958f88;
}

.btn-google {
  color: white;
  background-color: #958f88;
}

.btn-facebook {
  color: white;
  background-color: #958f88;
}

/* Fallback for Edge
      -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #958f88;
  }
}

/* Fallback for IE
      -------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color:#958f88;
  }
}

/* custom css  */
#login-form {
  /* height: 100%; */
}

.login-logo {
  display: block;
  width: 150px;
  margin: 10px auto;
}

h5.card-title.text-center {
  font-weight: 500;
}

#login-form h5.card-title.text-center {
  color: #3d3d3d;
}

.login-container
{
    width: 500px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    margin: 141px auto;
}

.form-container
{
    background-color: #000000;
    color: #e8e8e8;
    font-size: 15px;
    padding: 21px;
    box-shadow: 2px 0px 3px 3px #f3f3f3;
    -webkit-box-shadow: 2px 0px 3px 3px #f3f3f3;
}
.form-container .form-control
{
    background-color: #000;
    color: #e8e8e8;

}
.btn-clr
{
    background-color: #2288dc;
    font-weight: 600;
    font-size: 17px;
}
.img-width
{
    width: 137px;
    margin-left: 171px;
    margin-bottom: 11px;
    margin-top: 11px;

}
.header-div
{
    background-color: #1869aa;
    float: left;
    width: 100%;
    color: #ffffff;
    padding: 7px;
    position: relative;
}

.login-container .form-label {

    color: #e8e8e8;

}
.main-div
{
    width: 500px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0
}

.logo-img
{
    float: right;
    width: 57px;
    right: 51px;
    position: relative;
}
.header-div h3{
    float: left;
    font-size: 21px;
    line-height: 2;
    margin-bottom: 0px;
    margin-top: 3px;
}

.txt-clr
{
    color:#2ba341;
}

.cards-width
{
    width: 24%;
    float: left;
    margin: 7px;
    box-shadow: 0px 0px 3px 0px grey;
    -webkit-box-shadow: 0px 0px 3px 0px grey;
    border-radius: 7px;
    font-weight: 600;
    border: 0px;
}

.widget-icons
{
    position: absolute;
    top: 21px;
    right: 11px;
    font-size: 70px;
}

.spinner{
    min-height: 100%;
    width: 100%;
    position: fixed;
    /* color: #2ba341; */
    
}
.spinnerContent{

    position: absolute;

    margin: auto!important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

}


.font-size
{
    font-size: 27px;
    font-weight: 600;
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 11px;
    margin: auto
}
.displaySpinner {
  display: block;
}

body .card-title {
  font-size: 31px;
  color: #ffffff;
}
.errorBlock {
  font-size: 20px;
  color: red;
  margin-left: 40%;
  position: absolute;
  top: 11%;
  bottom: 0;
  margin: auto;
  width: 30%;
  left: 0;
  right: 0;
  font-weight: 600;
}
.card-text {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
}

.card.text-white {
  border: 0px;
  border-radius: 7px;
}
.spinnerContent {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.fontSize {
  font-size: 35px;
  position: absolute;
  top: 15px;
  right: 11px;
  color: #ffffff;
  font-weight: 600;
  border: 3px solid #82c1f3;
  padding: 3px;
  background-color: #2f91e1;
  border-radius: 23px;
  cursor: pointer;
}
.searchBT {
  /* backgroundColor:"transparent";  marginLeft:10,border:"2px solid white",color:"white",fontWeight:"bold",fontSize:13; */
  background-color: transparent;
  margin-left: 10px;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 13px;
}
.searchBTN:hover {
  background-color: white;
  color: black;
}
.name {
  background-color: transparent;
  margin-left: 10px;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  font-size: 13px;
}
.name:hover {
  background-color: white;
  color: black;
}

body .card-body .card-title {
  font-size: 31px;
  color: #ffffff;
}
.dashboard-card .card:not(.no-link):hover .card-body svg {
  font-size: 61px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  -ms-transition: 1s ease;
  -moz-transition: 1s ease;
}

.dashboard-card .card:not(.no-link):hover {
  cursor: pointer;
}
@media screen and (max-width: 1300px) {
  .widget-icons {
    font-size: 55px;
    margin-top: 0rem;
    right: 7px;
  }
  .card-text {
    margin-top: 1em;
  }
}

@media screen and (max-width: 767px) {
  .widget-icons {
    font-size: 73px;
  }
  .card-text {
    margin-top: 2em;
  }
}
.input-STB::-webkit-input-placeholder {
  color: #ffffff;
}
.input-STB::placeholder {
  color: #ffffff;
}

.lcoSearch {
  position: absolute;
  display: block;
  list-style: none;
  background-color: white;
  padding: 0px;
  box-shadow: 0px 1px 3px grey;
  z-index: 9;
  width: 30vw;
  top: 50px;
}

@media screen and (max-width: 767px) {
  .card-text {
    margin-top: 0em;
  }
}

/*custom css*/

/* log in */
.login-container {
  margin: 70px auto;
}

.name:hover,
.name:active,
.name:focus {
  background-color: #2b5b84;
  color: white;
  outline: none;
}

.header-div {
  /* background-image: linear-gradient(
    to right top,
    #051937,
    #0a4066,
    #006c96,
    #009cc3,
    #12cfeb
  ); */
  background-color: #040B26;
}

input.input-STB {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: 2px solid #fff !important;
  color: white !important;
}

svg.text-center {
  width: 82px;
  height: 77px;
  position: relative;
  top: -40px;
  color: #ffffff;
  border-radius: 68px;
  padding: 10px;
}

@media (min-width: 1024px) {
  .dashboard .col-md-3 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .dashboard .col-md-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .dashboard .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .dashboard .col-md-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .mt-5.stock-operation.row .col-md-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .transcation.row .col-md-3 {
    flex: 0 0 33%;
    max-width: 33%;
  }
}

@media (max-width: 570px) {
  .margin-center {
    margin: 0 30%;
  }
}
.circle {
  margin-bottom: -30px;
}

.card-body {
  margin-bottom: 20px;
}

svg.search {
  font-size: 25px;
}

.name {
  margin-left: 12px;
  font-weight: bold;
  font-size: 14px;
  background-color: #2b5b84;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: -1px;
  border-radius: 20px;
  width: 36px;
  height: 35px;
}

svg.fontSize.login {
  border: 3px solid rgb(25, 176, 40);
  background-color: rgb(21, 171, 36);
  margin-right: 50px;
}

svg.fontSize.login:hover {
  border: red;
  background-color: red;
}

.stb-details .card-body {
  height: 220px !important;
  overflow: hidden !important;
}
.stb-details .card {
  border: 1px solid rgba(130, 130, 130, 0.83) !important;
}
.customer-detail-heading {
  margin-top: 8px;
  font-size: 15px !important;
  color: #000000 !important;
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
}

label.align-pu {
  float: left;
  margin: 5px 5px;
  font-size: 18px;
}

.customer-name.col-md-8.col-sm-8 {
  font-size: 20px;
  color: #05547c;
  font-weight: 600;
}
.price.card-title.h5 {
  margin-top: -15px;
}

@media (min-width: 768px) {
  .cNYSzp {
    background: #bfec99;
    width: 70% !important;
    padding: 7px 47px;
    border-radius: 37px;
    position: relative;
    margin: 0 auto;
  }
}

.transaction-card {
  width: 20rem;
  text-align: center;
  background-color: #fff;
  margin: 0px auto;
  /* padding: 40px; */
  padding: 40px 40px 50px;
}

.transaction-card svg {
  width: 70px;
  height: 70px;
  position: relative;
  top: -70px;
  color: #fff;
  /* border-radius: 68px; */
  /* padding: 5px; */
  /* background: linear-gradient(to right, #6066d6, #7f84ee); */
}

li.search-list {
  font-size: 14px !important;
}

/* profile */

.profile .row {
  width: 85%;
  /* background: white; */
  background: #092533;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
}

.profile .circle {
  text-align: center;
  margin-bottom: 10px;
  margin-right: 45px;
}

.profile svg {
  height: 80px;
  width: 80px;
  color: white;
  background: #092533;
  border-radius: 40px;
  padding: 11px;
  margin-top: 40px;
}

.profile .col-md-4.col-sm-12,
.profile .col-md-8.col-sm-12 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.profile label {
  font-size: 17px;
  color: #c5c5c5;
  margin-right: 5px;
}

.profile .edit {
  background: grey;
  color: white;
  margin-left: 25px;
  cursor: pointer;
  padding: 2px 5px;
  font-size: 15px;
}

tbody tr td:nth-child(8) {
  background-color: transparent !important;
}

.table th,
.table thead {
  padding: 7px;
  text-transform: uppercase;
  font-size: 15px;
  border: 1px solid #ffff;
  /* background-color: #093559 !important; */
  background: #366cc8;
  color: #fff;
}

table.table.table-striped {
  text-align: center;
}

.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #1569bd; */
  text-align: center;
}

table .total-records {
  height: 500px;
}

.jOzkvp {
  margin-bottom: 40px;
}

.notify-div .card {
  height: 200px !important;
  overflow: hidden !important;
  /* background: #f1f1f1; */
  /* background: linear-gradient(to right, #00b4db, #0083b0); */
  /* background: #ff9068; */
  background: #ee9ca7;
}

.notification .icon {
  margin: 5px 0px;
}

.notification .icon svg.mr-3 {
  width: 20px;
  height: 20px;
}

.ireVdR {
  margin-bottom: 5px;
  padding: 2px;
  font-size: 21px;
  line-height: 2.3;
  border: 0px solid #ececec;
  text-align: center;
  margin-top: -15px;
}

.lcocode {
  display: inline-block;
  color: #f14444;
  font-size: 20px;
  margin-left: 30px;
  font-weight: bold;
}
.lcocode span {
  color: black;
  margin-left: 0px;
  font-size: 20px;
}

/* bottom nav */
/* .bottom-nav-active {
  color: white;
} */

.undrawimg {
  height: 150px !important;
  width: 300px !important;
  margin-top: 20px;
  display: block;
  margin-bottom: 10px;
  margin-left: 20px;
}

.stb-search input {
  width: 25%;
  border-radius: 30px;
  border: 2px solid #060606;
  text-align: center;
  height: 35px;
}
.stb-returns input {
  width: 17%;
  border-radius: 30px;
  border: 2px solid #060606;
  text-align: center;
  height: 35px;
}

.ejdDjE {
  color: #004085 !important;
}
/* .ejdDjE {
  color: #ffffff !important;
} */
svg.details {
  color: #1a237e;
  font-size: 20px;
}

textarea {
  resize: none;
}

li.data-product {
  list-style: none;
  display: inline-block;
}

.fLxwLo {
  /* background: linear-gradient(to right, #b993d6, #8ca6db); */
  /* background: linear-gradient(to right, rgb(173, 83, 137), rgb(60, 16, 83)); */
  padding: 9px;
  line-height: 1.5;
}
/* .bNwSfB {
  color: #ffffff !important;
} */

.edit-address label {
  margin-top: -100px;
}

#edit-customer-details input {
  width: 70%;
}

@media (max-width: 945px) {
  .edit-address label {
    margin-top: -30px;
  }
  #edit-customer-details input {
    width: 100%;
  }
}

table.table.table-striped {
  font-size: 14px;
}
.pay-disc {
  margin-left: 10%;
}

@media (max-width: 1050px) {
  .pay-disc {
    margin-left: 5%;
  }
}

.iWIjkB {
  color: #132275 !important;
}

.bouncechk {
  display: block !important;
}

.displaySpinner{
    display: block;
}
.search-icons
{
    position: absolute;
    left: 17px;
    top: 17px;
}

.textBox:focus
{
    outline: none;
}

.stb-details .card-body
{
    height: 217px;
    overflow: auto;
}
.stb-details .card-footer
{
background-color: #ffffff;
border-top: 1px solid #f3f0f0;
}
body .card.stb-details .card-title{
    font-size: 15px;
    color: #19b8ce;
    line-height: 1.5;
}
.card.stb-details{
box-shadow: 1px 1px 3px 1px gainsboro;
-webkit-box-shadow: 1px 1px 3px 1px gainsboro;
}


.button-box
{
    float: right;
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 11px;
}
.search-div
{
    display: inline;
    float: left;
    border: 1px solid #f7f7f7;
    padding: 3px;
    margin-top: 3px;
    width: 17%;
    position: relative;
    border-radius: 7px;
}
.btn-align-right
{
    float: right;
}
.search-div svg
{
position: absolute;
    right: 0;
    top: 9px;
    right: 21px;
}
.search-div input
{
    border: 0px;
    width: 100%;
}
.search-div input:focus
{
    border:none;
}
table thead
{
    background-image: linear-gradient(90deg, #31bbbf, #61e5ea);
    background-image: -webkit-linear-gradient(90deg, #31bbbf, #61e5ea);
}
table tbody td
{
    border: 0px solid #f9f9f9;
}
tbody tr:nth-child(odd)
{
    background-color: #fbfbfb;
}
table th {
    padding: 7px;
    text-transform: uppercase;
    font-size: 15px;
    border: 1px solid #19dce4;
}

.checkbox{
    display: none;
}
.addon-price
{
    font-weight: 600;
    display: inline;
    float: left;
    margin-left: 21px;
    margin-top: 3px;
    font-size: 21px;
}
.refresh-icons
{
    font-size: 21px;
    transition: 1s ease;
    transition: 1s ease;
}
.refresh-icons:hover
{
    cursor: pointer;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  color:green;
}
body thead th{
    text-align: left;
}
.pack-div ul
{
    position: absolute;
    display: none;
    list-style: none;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 1px 3px grey;
    z-index: 9;
    width:100%;

}
.pack-div
{
    position: relative;
}

.pack-div:hover > ul {
    display: block;
    max-height: 271px;
    overflow: auto;
}
.pack-div > div{
    text-align: center;
}
.pack-div > div{
    text-align: center;
}
table tbody td.pack-div
{
position: relative;
}
.pack-div > div a{
color: #007bff!important;
font-weight: 600;
text-align: center;
cursor: pointer;
}
.pack-div ul a li
{
    padding: 5px 7px;
    background-color: #fbfdff;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
}
.pack-div .show-pack:hover
{
    color: red!important;
}

.pack-div .show-pack
{
    color: #007bff!important;
    font-weight: 600;
    text-decoration: underline!important;
    cursor: pointer;
}
.card-title label {
  font-size: 17px;
  margin-left: 3px;
  margin-right: 7px;
}
.pull-right {
  float: right;
}
.card-shadow {
  box-shadow: 0px 0px 3px 0px #dadada;
  -webkit-box-shadow: 0px 0px 3px 0px #dadada;
}
.back-button {
  color: #ffffff;
  font-size: 41px;
  top: 7px;
  position: relative;
  float: left;
}
body .card-shadow .card-title {
  font-size: 17px;
  color: #000000;
}
.displaySpinner {
  display: block;
}
body .card-title label {
  font-size: 19px;
  color: rgb(0, 0, 0);
}
body .css-3pxmmn > div:nth-last-of-type(1) {
  padding: 51px;
}
.selected-nm {
  margin-left: 21px;
  margin-bottom: 11px;
  float: left;
  width: 100%;
  font-size: 17px;
  color: #6a8ddc;
}
.package-details {
  border-bottom: 0px solid #eaeaeaf7;
  border-radius: 0px;
  border-top: 0px;
}

.package-details .card-body {
  padding: 11px;
}
.package-details:nth-child(even) {
  background-color: #f0f7eb14;
}
.backTo {
  font-size: 47px;
  float: left;
  cursor: pointer;
}
.pack-box {
  padding: 0px 7px;
}
.btn {
  cursor: pointer;
}
.selected-div {
  position: relative;
  max-height: 121px;
  overflow: auto;
  border: 3px solid #f9f9f9;
  padding: 11px;
  border-radius: 7px;
}
.selected-div svg {
  margin-left: 11px;
  top: 3px;
  float: right;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 13px;
  font-size: 17px;
  padding: 0px;
}
.selected-div svg:hover {
  border: 2px solid red;
  color: red;
}
.bal-div label {
  color: red;
  font-size: 19px;
  font-weight: 600;
}
.bal-div > div {
  margin: 0px 21px;
}
.footer-div {
  padding: 11px;
  box-shadow: 3px 2px 3px 2px gainsboro;
  -webkit-box-shadow: 3px 2px 3px 2px gainsboro;
  border-top: 1px solid #f1f1f1;
}
.processing-div.card {
  background-color: #fff0;
  color: #ffffff;
  border: 0px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}
.processing-div .rai-activity-indicator {
  display: block;
  margin-left: 27%;
  width: 100%;
}
.processing-div .card-footer {
  border: 0px;
  background: #ffffff00;
  padding: 0px;
}
.selected-div > label > label {
  display: none;
}
.popup-icon {
  position: relative;
  top: -3px;
}
.transactions-list .card .card-body > div {
  display: inline;
  color: #d61818;
  float: left;
  font-weight: 600;
  font-size: 17px;
  margin-right: 11px;
}
.transactions-list .card .card-body {
  padding: 7px 11px;
}
.transactions-list .card .card-body > div label {
  color: #000000;
}
thead {
  background-color: #35e0e8;
  color: #ffffff;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
}
tbody td {
  padding: 11px;
  border: 1px solid #f9f9f9;
}
thead td {
  padding: 11px 31px;
  border: 1px solid #f9f9f9;
}
tbody tr:nth-child(odd) {
  background-color: #f8ffff;
}
tbody tr td:nth-child(8) {
  background-color: #fdfdfd;
}
tbody {
  display: block;
  height: 287px;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
.popupwidth > div {
  width: 71%;
}
.popupwidth .stbicons {
  top: 15px;
}
.successicons {
  font-size: 107px;
  color: green;
  display: block;
  border: 7px solid green;
  margin: auto;
  border-radius: 81px;
  width: 150px;
  height: 150px;
  margin-top: 21px;
}
.confirm-popup {
  width: 437px;
}

.pack-div ul
{
    position: absolute;
    display: none;
    list-style: none;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 1px 3px grey;
    z-index: 9;
    width:100%;

}
.pack-div
{
    /* position: relative; */
}

.pack-div:hover > ul {
    display: flex;
    max-height: 271px;
    overflow: auto;
    position: absolute;
    z-index:100;
}
.pack-div > div{
    text-align: center;
}
.pack-div > div{
    text-align: center;
   
    
    
}
.above > div{
  display:none ;
  margin-top:61px
}
.above:hover > div{

  position: absolute;
  display:block ;
   list-style: none;
   background-color: white;
padding : 0px;
box-shadow: 0px 1px 3px grey;
   z-index: 9;
  width: 12vw;
  top: 50px;
  overflow: auto;
max-height: 20vh;
                                                
  margin-top: 61px,

}
table tbody td.pack-div
{
position: absolute;
}
.pack-div > div a{
color: #007bff!important;
font-weight: 600;
text-align: center;
cursor: pointer;
}
.pack-div ul a li
{
    padding: 5px 7px;
    background-color: #fbfdff;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
}
.pack-div .show-pack:hover
{
    color: red!important;
}

.pack-div .show-pack
{
    color: #007bff!important;
    font-weight: 600;
    text-decoration: underline!important;
    cursor: pointer;
}

@media screen and (max-width: 1457px) {
  .popupwidth > div {
    width: 80%;
  }
}

@media screen and (max-width: 1170px) {
  .popupwidth > div {
    width: 100%;
  }
}
.displaySpinner {
  display: block;
}
.transactions-btn svg {
  font-size: 61px;
  display: block;
  text-align: center;
  margin: auto;
}
.transactions-btn .btn {
  font-weight: 600;
  margin-right: 27px;
  background-color: #fdfdfd;
  box-shadow: 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(1) {
  color: #7dca7d;
}
.transactions-btn .btn:focus {
  box-shadow: inset 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(2) {
  color: #f98d4c;
}
.transactions-btn .btn:nth-of-type(3) {
  color: #d6d225;
}
table .total-records {
  height: 463px;
}
table th {
  padding: 7px;
}

body .notify-div .card-title {
  font-size: 17px;
  color: #16a7caf5;
}
.notify-div .card-body div {
  font-weight: 600;
}

.notify-div .card {
  box-shadow: 1px 1px 3px 1px gainsboro;
  -webkit-box-shadow: 1px 1px 3px 1px gainsboro;
  height: 187px;
  overflow: auto;
  background: linear-gradient(180deg,#ffefba,#fff);
}

.notify-div {
  height: 561px;
  overflow: auto;
}

.profileContentContainer {
  margin-top: 10px;
  transition: all 1s;
}
.profileContentContainer:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-image: linear-gradient(to bottom right, #7774ff, #86ebff);
  color: white;
}
.constentValueStyle {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: gray;
  font-size: 18px;
  margin-left: 5%;
}
.constentValueStyle:hover {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: white;
  font-weight: bold;
}
.dynamicValue {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: gray;
  font-size: 18px;
  font-weight: bold;
  margin-left: 50%;
}

.dynamicValue:hover {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: white;
}

.form-label,.form-check-label
{
    font-weight: 600;
    color: #e9b42a;
    font-size: 15px;
}
.form-label span{
    color: #f19494;
}
.form-div
{
    box-shadow: 0px 0px 2px 0px #e0e5e0;
    -webkit-box-shadow: 0px 0px 2px 0px #e0e5e0;
    padding: 21px;
}
.form-div h3{
    font-size: 23px;
}
.backTo
{
    font-size:47px;
    float:left;
    cursor: pointer;
}
.displaySpinner {
  display: block;
}
.transactions-btn svg {
  font-size: 61px;
  display: block;
  text-align: center;
  margin: auto;
}
.transactions-btn .btn {
  font-weight: 600;
  margin-right: 27px;
  background-color: #fdfdfd;
  box-shadow: 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(1) {
  color: #7dca7d;
}
.transactions-btn .btn:focus {
  box-shadow: inset 0 0 7px #cecbcb;
}
.transactions-btn .btn:nth-of-type(2) {
  color: #f98d4c;
}
.transactions-btn .btn:nth-of-type(3) {
  color: #d6d225;
}
table .total-records {
  height: 463px;
}
table th {
  padding: 7px;
}

body .notify-div .card-title {
  font-size: 17px;
  color: #16a7caf5;
}
.notify-div .card-body div {
  font-weight: 600;
}

.notify-div .card {
  box-shadow: 1px 1px 3px 1px gainsboro;
  -webkit-box-shadow: 1px 1px 3px 1px gainsboro;
  height: 187px;
  overflow: auto;
  background: linear-gradient(180deg,#ffefba,#fff);
}

.notify-div {
  height: 561px;
  overflow: auto;
}

.profileContentContainer {
  margin-top: 10px;
  transition: all 1s;
}
.profileContentContainer:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-image: linear-gradient(to bottom right, #7774ff, #86ebff);
  color: white;
}
.constentValueStyle {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: gray;
  font-size: 18px;
  margin-left: 5%;
}
.constentValueStyle:hover {
  /* color:"gray",fontSize:18,marginLeft:"5%"  */
  color: white;
  font-weight: bold;
}
.dynamicValue {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: gray;
  font-size: 18px;
  font-weight: bold;
  margin-left: 50%;
}

.dynamicValue:hover {
  /* color:"gray",fontSize:18,justifySelf:"flex-end",fontWeight:"bold",marginLeft:"50%" */
  color: white;
}

.card-shadow
{
    box-shadow:0px 3px 3px 0px #e9dfdf;
    -webkit-box-shadow:0px 3px 3px 0px #e9dfdf;
}

.stbicons
{
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 17px;
    margin: auto;
    /* bottom: 0; */
    color: #1bdc54;
    font-weight: 600;
    border: 2px solid #1bdc54;
    padding: 3px;
    border-radius: 7px;
    font-style: normal;
}
.stbicons:hover{
    background: #1bdc54;
    color: #ffffff;
    cursor: pointer;
}
.card-title
{
    font-size: 17px;
    color: #19b8ce;
    margin-bottom: 7px;
}
body .card-shadow .card-title
{
    font-size: 17px;
    color: #19b8ce;
}
body .packDiv .card-body .card-title
{
    font-size: 21px;
    color: #35c8ea;
}
.check-pack
{
    position: absolute;
    right: 0;
    top: 0;
}
body .packDiv .card-body .text-muted {
    color: #ff3131!important;
}

.checkBox{
    margin-left: 89%;
}

.displaySpinner {
    display: block;
  }
  .spinnerContent {
    position: absolute;
  
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
.checkBox {
  margin-left: 89%;
}

.displaySpinner {
  display: block;
}
.spinnerContent {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

input,
input:active,
input:focus {
  outline: none;
}
input.stb-transfer {
  width: 25%;
  padding: 4px;
  border: 2px solid #297186;
  border-radius: 20px;
  text-align: center;
}

.checkBox{
    margin-left: 89%;
}

.displaySpinner {
    display: block;
  }
  .spinnerContent {
    position: absolute;
  
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
.checkBox {
  margin-left: 89%;
}

.displaySpinner {
  display: block;
}
.spinnerContent {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

input,
input:active,
input:focus {
  outline: none;
}
input.stb-transfer {
  width: 25%;
  padding: 4px;
  border: 2px solid #297186;
  border-radius: 20px;
  text-align: center;
}

.checkBox {
    margin-left: 89%;
  }
  
  .displaySpinner {
    display: block;
  }
  .spinnerContent {
    position: absolute;
  
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  input,
  input:active,
  input:focus {
    outline: none;
  }
  input.stb-transfer {   
    width: 25%;
    padding: 4px;
    border: 2px solid #3D95F7;
    border-radius: 20px;
    text-align: center;
  }
  
.checkBox {
    margin-left: 89%;
  }
  
  .displaySpinner {
    display: block;
  }
  .spinnerContent {
    position: absolute;
  
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  input,
  input:active,
  input:focus {
    outline: none;
  }
  input.stb-transfer {
    width: 25%;
    padding: 4px;
    border: 2px solid #297186;
    border-radius: 20px;
    text-align: center;
  }
  
.checkBox {
    margin-left: 89%;
  }
  
  .displaySpinner {
    display: block;
  }
  .spinnerContent {
    position: absolute;
  
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  input,
  input:active,
  input:focus {
    outline: none;
  }
  input.stb-transfer {
    width: 25%;
    padding: 4px;
    border: 2px solid #297186;
    border-radius: 20px;
    text-align: center;
  }
  
.button-box
{
    float: right;
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 11px;
}
.search-div
{
    display: inline;
    float: left;
    border: 1px solid #f7f7f7;
    padding: 3px;
    margin-top: 3px;
    width: 17%;
    position: relative;
    border-radius: 7px;
}
.btn-align-right
{
    float: right;
}
.search-div svg
{
position: absolute;
    right: 0;
    top: 9px;
    right: 21px;
}
.search-div input
{
    border: 0px;
    width: 100%;
}
.search-div input:focus
{
    border:none;
}
table thead
{
    background-image: linear-gradient(90deg, #31bbbf, #61e5ea);
    background-image: -webkit-linear-gradient(90deg, #31bbbf, #61e5ea);
}
table tbody td
{
    border: 0px solid #f9f9f9;
}
tbody tr:nth-child(odd)
{
    background-color: #fbfbfb;
}
table th {
    padding: 7px;
    text-transform: uppercase;
    font-size: 15px;
    border: 1px solid #19dce4;
}

.checkbox{
    display: none;
}
.addon-price
{
    font-weight: 600;
    display: inline;
    float: left;
    margin-left: 21px;
    margin-top: 3px;
    font-size: 21px;
}
.refresh-icons
{
    font-size: 21px;
    transition: 1s ease;
    transition: 1s ease;
}
.refresh-icons:hover
{
    cursor: pointer;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  color:green;
}
body thead th{
    text-align: left;
}
.pack-div ul
{
    position: absolute;
    display: none;
    list-style: none;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 1px 3px grey;
    z-index: 9;
    width:100%;

}
.pack-div
{
    position: relative;
}

.pack-div:hover > ul {
    display: block;
    max-height: 271px;
    overflow: auto;
}
.pack-div > div{
    text-align: center;
}
.pack-div > div{
    text-align: center;
}
table tbody td.pack-div
{
position: relative;
}
.pack-div > div a{
color: #007bff!important;
font-weight: 600;
text-align: center;
cursor: pointer;
}
.pack-div ul a li
{
    padding: 5px 7px;
    background-color: #fbfdff;
    border-bottom: 1px solid #e8e8e8;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
}
.pack-div .show-pack:hover
{
    color: red!important;
}

.pack-div .show-pack
{
    color: #007bff!important;
    font-weight: 600;
    text-decoration: underline!important;
    cursor: pointer;
}
